import { useField } from 'formik';
import { FormControl, FormHelperText, Radio, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';
import { useEffect } from 'react';

interface Props {
    name: string;
    label: string;
    options: Map<string, string>;
    setUserLanguage?: (Language: string) => void;
}

const InputRadioGroup = ({ label, options, setUserLanguage, ...props }: Props) => {
    const [field, meta, helpers] = useField(props);

    useEffect(() => {
        //set parent/"global" App language state when language radio field is changed
        if (field.name === 'Language') {
            setUserLanguage?.(field.value)
        }
    }, [field.value])

    const items = Array.from(options).map(([key, val]) =>
        <FormControlLabel
            value={key}
            key={key}
            control={<Radio />}
            label={val}
        />
    );

    const error = meta.touched && Boolean(meta.error);
    const labelId = field.name + '-label';
    const helperId = field.name + '-helper';
    let ariaLanguageLabel = label;

    if (field.name === 'Language') {
        ariaLanguageLabel = "Please select a language. Por favor, seleccione un idioma";
    }

    return (
        <FormControl error={error}>
            {field.name === 'Language' ?
                <FormLabel sx={{ display: 'none' }} id={labelId}>{ariaLanguageLabel}</FormLabel>
                :
                <FormLabel id={labelId}>{label}</FormLabel>
            }
            <RadioGroup
                row
                aria-labelledby={labelId}
                aria-describedby={helperId}
                {...field}
                {...props}
            >
                {items}
            </RadioGroup>
            <FormHelperText id={helperId} className="formHelperText">{meta.touched && meta.error}</FormHelperText>
        </FormControl>
    );
}

export default InputRadioGroup;