import { TextField } from '@mui/material';
import { useField } from 'formik';

interface Props {
    label: string;
    name: string;
    isEnglish?: boolean;
}

const InputTextField = ({ isEnglish, label, ...props }: Props) => {
    const [field, meta, helpers] = useField(props);

    /* This validation is being done here because yup.date() considers number like '9', '010', etc valid dates.
     When using yup.date() (date() type is needed for correct min/max date validation) you cannot check the length 
     of the actual STRING input value because yup immediately (tries) to create a date from it. Manually setting
     meta.error in here means the length validation and its error get checked/shown FIRST, then if it passes the
     validation it will move onto to showing the yup() validation errors if relevant. */
    if (field.name === 'DateOfBirth') {
        if (field.value && (field.value.length < 10 || field.value.length > 10)) {
            meta.error = isEnglish ? "Please enter in MM/DD/YYYY format" : "Por favor ingrese en formato MM/DD/YYYY";
        }
    }
    return (
        <TextField
            sx={{ flexGrow: 1 }}
            fullWidth
            label={label}
            //aria-label={label}
            {...field}
            {...props}
            inputProps={{"aria-label": label}}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
        />
    );
}

export default InputTextField