import { Button } from '@mui/material';
import { ISelfReferralFormFields } from '../../interfaces/ISelfReferralFormFields';
import { useFormikContext } from 'formik';
import * as MISC_CONSTANTS from '../../staticResources/MiscConstants';
import { useState } from 'react';
import LoadingSpinner from '../parentForms/shared/LoadingSpinner';
import { SendConfirmationEmail } from '../../services/HttpService';

interface Props {
    isEnglish: boolean;
    centerName: string;
    confirmationNumber: string;
    submissionPageContent?: string;
}

const SubmissionConfirmation = ({ isEnglish, centerName, confirmationNumber, submissionPageContent }: Props) => {
    const { values } = useFormikContext<ISelfReferralFormFields>();
    const [sendingEmail, setSendingEmail] = useState(false);
    const [hideButton, setHideButton] = useState(false);

    const sendEmail = async () => {
        //loading spinner
        setSendingEmail(true);

        const alertMessage = await SendConfirmationEmail(confirmationNumber, setSendingEmail, setHideButton, isEnglish);

        alert(alertMessage);
    }

    return (
        <>
            <div className="informationalContent">
                <h2 tabIndex={0} style={{ color: 'green' }}>{isEnglish ? `Your information has been submitted to ${centerName}`
                    : `Su información ha sido enviada a ${centerName}`}
                </h2>
                <h2 tabIndex={0}>{isEnglish ? `Confirmation Code: ${confirmationNumber}`
                    : `Número de confirmación ${confirmationNumber}`}
                </h2>
                {submissionPageContent &&
                    <p tabIndex={0}>{submissionPageContent}</p>
                }
                {(values.Email && !hideButton) &&
                    <>
                        <h4 tabIndex={0}>{isEnglish ? `Use the button below to send a confirmation to ${values.Email}`
                            : `Utilice el botón a continuación para enviar una confirmación a ${values.Email}`}
                        </h4>
                        <Button
                            tabIndex={0}
                            variant='contained'
                            color='primary'
                            sx={{ margin: 2, width: "100px" }}
                            onClick={sendEmail}
                        >
                            {isEnglish ? 'Send' : 'Enviar'}
                        </Button>
                    </>
                }

                {/* Loading Spinner */}
                <LoadingSpinner
                    isLoading={sendingEmail}
                />
            </div>

            <p className="pageFooter">
                {MISC_CONSTANTS.careDxBrand}
                <br />
                <a tabIndex={0} href={MISC_CONSTANTS.careDxPrivacyLink} target="_blank" rel="noreferrer">{isEnglish ? "Privacy Statement" : "Declaracion de privacidad"}</a>
            </p>
        </>
    );
}

export default SubmissionConfirmation;
