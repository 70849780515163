import InputTextField from '../inputs/InputTextField';
import InputSelectField from '../inputs/InputSelectField';
import { Grid } from '@mui/material';
import InputRadioGroup from '../inputs/InputRadioGroup';
import { ContactMethods, ContactMethodsSpanish, States } from '../../staticResources/InputOptions';
import { SelfReferralFormSteps } from '../../staticResources/FormStepsForLabels';
import { getLabels } from '../../services/LabelService';
import * as INPUT_LABEL_KEYS from '../../staticResources/InputLabelKeys';

interface Props {
    isEnglish: boolean;
}

const ContactInformation = ({ isEnglish }: Props) => {
    const labels = getLabels(isEnglish, SelfReferralFormSteps.ContactInfo);

    return (
        <Grid container spacing={1}>
            <Grid item md={6} sm={12} xs={12} lg={6}>
                <InputTextField
                    name="Address1"
                    label={labels.get(INPUT_LABEL_KEYS.address1LabelKey) ?? ''}
                />
            </Grid>
            <Grid item md={6} sm={12} xs={12} lg={6}>
                <InputTextField
                    name="Address2"
                    label={labels.get(INPUT_LABEL_KEYS.address2LabelKey) ?? ''}
                />
            </Grid>
            <Grid item md={4} sm={12} xs={12} lg={4}>
                <InputTextField
                    name="City"
                    label={labels.get(INPUT_LABEL_KEYS.cityLabelKey) ?? ''}
                />
            </Grid>
            <Grid item md={4} sm={12} xs={12} lg={4}>
                <InputSelectField
                    name="State"
                    label={labels.get(INPUT_LABEL_KEYS.stateLabelKey) ?? ''}
                    options={States}
                />
            </Grid>
            <Grid item md={4} sm={12} xs={12} lg={4}>
                <InputTextField
                    name="Zip"
                    label={labels.get(INPUT_LABEL_KEYS.zipLabelKey) ?? ''}
                />
            </Grid>
            <Grid item md={4} sm={12} xs={12} lg={4}>
                <InputTextField
                    name="Email"
                    label={labels.get(INPUT_LABEL_KEYS.emailLabelKey) ?? ''}
                />
            </Grid>
            <Grid item md={4} sm={12} xs={12} lg={4}>
                <InputTextField
                    name="HomePhone"
                    label={labels.get(INPUT_LABEL_KEYS.homePhoneLabelKey) ?? ''}
                />
            </Grid>
            <Grid item md={4} sm={12} xs={12} lg={4}>
                <InputTextField
                    name="CellPhone"
                    label={labels.get(INPUT_LABEL_KEYS.cellPhoneLabelKey) ?? ''}
                />
            </Grid>
            <Grid item md={12} sm={12} xs={12} lg={12}>
                <InputRadioGroup
                    name="PrefContactMethod"
                    label={labels.get(INPUT_LABEL_KEYS.prefContactMethodLabelKey) ?? ''}
                    options={isEnglish ? ContactMethods : ContactMethodsSpanish}
                />
            </Grid>
        </Grid>
    );
}

export default ContactInformation