import { useField } from 'formik';
import { FormControl, FormHelperText, InputLabel, Select, MenuItem } from '@mui/material';

interface Props {
    name: string;
    label: string;
    options: Map<string, string>;
}

const InputSelectField = ({ label, options, ...props }: Props) => {
    const [field, meta, helpers] = useField(props);
    const items = Array.from(options).map(([key, val]) => <MenuItem key={key} value={key}>{val}</MenuItem>);
    const error = meta.touched && Boolean(meta.error);;
    const labelId = field.name + '-label';
    const helperId = field.name + '-helper';

    return (
        <FormControl error={error} fullWidth>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                labelId={labelId}
                aria-labelledby={labelId}
                aria-describedby={helperId}
                label={label}
                {...field}
                {...props}
            >
                {items}
            </Select>
            <FormHelperText id={helperId} className="formHelperText">{meta.touched && meta.error}</FormHelperText>
        </FormControl>
    );
}

export default InputSelectField