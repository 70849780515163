import axios, { AxiosError } from "axios";
import { ITransplantProgramInfo } from "../interfaces/ITransplantProgramInfo";
import { ISelfReferralFormFields } from "../interfaces/ISelfReferralFormFields";
import ReCAPTCHA from "react-google-recaptcha";
import { postError } from "../staticResources/MiscConstants";
import * as MISC_CONSTANTS from '../staticResources/MiscConstants';
import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";

const parentReq = axios.create({
  //when running locally uses .env.local otherwise uses .env
  baseURL: process.env.REACT_APP_TXACCESS_API_URL,
  headers: {
    "Content-type": "application/json"
  }
});

export const GetTransplantProgramInfo = async (pid: string, setErrorText: (errorText: string) => void, setLoading: (loading: boolean) => void,
  setProgramData: (programData: ITransplantProgramInfo) => void, appInsights: ApplicationInsights) => {

  let subDirAndParams = `config?pid=${pid}`;
  const displayError = "There was an issue with fetching necessary information, please try again. Se produjo un problema al obtener la información necesaria. Vuelva a intentarlo.";
  let consoleMessage = "";

  setLoading(true);
  await parentReq.get<ITransplantProgramInfo>(subDirAndParams).then((response: any) => {

    /*When txaccess gets a bad URL it still returns a 200 success code, but the content is an entire error web page. Check for the ACTUAL
        response content we care about. */
    if (!response.data || !response.data.CenterName) {
      throw Error("No valid Transplant Config response data from URL: " + response.config?.baseURL + response.config?.url);
    }

    setProgramData(response.data);
    setLoading(false);

  }).catch((e) => {

    //move to app insights logging when implemented
    if (e instanceof AxiosError) {
      consoleMessage = e.message + " AXIOS URL: " + e.config?.baseURL + e.config?.url;
    } else {
      consoleMessage = e.message
    }
    appInsights.trackTrace({message: consoleMessage, severityLevel: SeverityLevel.Error});
    //returning this for now until stakeholders specify something different
    setErrorText(displayError);
    setLoading(false);
  });
}

export const SendConfirmationEmail = async (confirmationNumber: string, setSendingEmail: (sendingEmail: boolean) => void, setHideButton: (hideButton: boolean) => void, isEnglish: boolean) => {
  let subDirAndParams = `referral/sendconfirmation/${confirmationNumber}`;
  let alertMessage = "";

  await parentReq.post<any>(subDirAndParams).then((response: any) => {

    if (!response.data || !response.data.Sent) {
      throw new Error("No valid response data or data is missing 'Sent' for confirmation number " + confirmationNumber);
    }

    if (response.data.Sent === "Success") {
      //hide email button upon success to prevent re-sending
      setHideButton(true);
      setSendingEmail(false);
      alertMessage = isEnglish ? `Email sent succesfully for Confirmation Code: ${confirmationNumber}` : `Correo electrónico enviado con éxito para el código de confirmación: ${confirmationNumber}`;
    } else {
      throw new Error("Email for confirmation number " + response.data.Sent);
    }

  }).catch((e) => {

    //move to app insights logging when implemented
    if (e instanceof AxiosError) {
      console.log(e.message + " URL: " + e.config?.baseURL + e.config?.url);
    } else {
      console.log(e.message);
    }

    alertMessage = isEnglish ? MISC_CONSTANTS.emailError : MISC_CONSTANTS.emailErrorSpanish;
    setSendingEmail(false);
  });

  return alertMessage;
}

export const PostReferral = async (progId: string, rCRef: React.RefObject<ReCAPTCHA>, setSubmitLoading: (loading: boolean) => void, values?: ISelfReferralFormFields) => {
  let submitResponse = { ConfirmationCode: '', ErrorMessage: '' };

  if (values) {
    const token = await rCRef.current?.executeAsync();
    const subDirAndParams = 'referral';
    setSubmitLoading(true);
    rCRef.current?.reset();

    values.ProgramID = progId;
    //Clear recipient name here if it's decided that selecting 'any recipient' and specific recipient are mutually exclusive

    if (token) {
      await parentReq.post<any>(subDirAndParams, values).then((response: any) => {

        /*When txaccess gets a bad URL it still returns a 200 success code, but the content is an entire error web page. Check for the ACTUAL
            response content we care about (ConfirmationCode) to determine if the POST was actually a success. */
        if (!response.data || !response.data.ConfirmationCode) {
          throw Error;
        }

        submitResponse.ConfirmationCode = response.data.ConfirmationCode;

      }).catch((e: Error) => {
        //the error message that comes back from txaccess is an entire webpage so we don't want to use that
        submitResponse.ErrorMessage = values.Language === MISC_CONSTANTS.english ? postError : MISC_CONSTANTS.postErrorSpanish;
      })

    } else {
      //move this console.log to app insights when set up
      console.log("Problem with reCaptcha");
      submitResponse.ErrorMessage = values.Language === MISC_CONSTANTS.english ? postError : MISC_CONSTANTS.postErrorSpanish;
    }

  } else {
    //move this console.log to app insights when set up
    console.log("Necessary values were not present");
    submitResponse.ErrorMessage = MISC_CONSTANTS.english + " " + MISC_CONSTANTS.postErrorSpanish;
  }

  let resp = submitResponse;
  return resp;
}