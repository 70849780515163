//CSS CLASS NAMES (just the ones used multiple times)
export const confirmPageHeaders = "confirmPageHeaders";
export const confirmPageList = "confirmPageList";
export const navButton = "navButton";

//ERROR MESSAGES
export const postError = "There was a communication error. Please try again or contact support for assistance at 720-738-8081";
export const postErrorSpanish = "Hubo un error de comunicación. Inténtelo de nuevo o comuníquese con soporte para obtener ayuda al 720-738-8081"
export const emailError = "Encountered an issue when attempting to send confirmation email";
export const emailErrorSpanish = "Encontré un problema al intentar enviar un correo electrónico de confirmación";

//NON-DISPLAY STEP NAMES
export const confirmInformation = "ConfirmInformation";

//FOOTER CONSTANTS
export const careDxBrand = "Powered by CareDx";
export const careDxPrivacyLink = "https://caredx.com/privacy-center/";

//LANGUAGE
export const english = "english";

//BUTTON PROPS
export const outlined = "outlined";
export const primary = "primary";