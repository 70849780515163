interface Props {
    //making this nullable to make the code happy but a check happens prior to setting this prop to ensure it has a value
    centerPageContent?: string;
}


const CenterInformation = ({ centerPageContent }: Props) => {

    return (
        <div tabIndex={0} className="informationalContent">
            <p>
                {centerPageContent}
            </p>
        </div>
    );
}

export default CenterInformation