import InputTextField from '../inputs/InputTextField';
import { Grid } from '@mui/material';
import { YesNoOptions, YesNoOptionsSpanish } from '../../staticResources/InputOptions';
import { SelfReferralFormSteps } from '../../staticResources/FormStepsForLabels';
import { getLabels } from '../../services/LabelService';
import * as INPUT_LABEL_KEYS from '../../staticResources/InputLabelKeys';
import InputRadioGroup from '../inputs/InputRadioGroup';

interface Props {
    isEnglish: boolean;
}

const RecipientInformation = ({ isEnglish }: Props) => {
    const labels = getLabels(isEnglish, SelfReferralFormSteps.RecipientInfo);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item md={3} sm={12} xs={12} lg={3}>
                    <InputRadioGroup
                        name="NonDirectedDonor"
                        label={labels.get(INPUT_LABEL_KEYS.nonDirectedDonorLabelKey) ?? ''}
                        options={isEnglish ? YesNoOptions : YesNoOptionsSpanish}
                    />
                </Grid>
                <Grid item md={3} sm={12} xs={12} lg={3}>
                    <InputTextField
                        name="RecipientName"
                        label={labels.get(INPUT_LABEL_KEYS.recipientNameLabelKey) ?? ''}
                    />
                </Grid>
                <div className="fillerDiv"></div>
            </Grid>
        </>
    );
}

export default RecipientInformation