import { Divider } from '@mui/material';
import { BirthSexes, ContactMethods, ContactMethodsSpanish, States, WeightUnits, WeightUnitsSpanish, YesNoOptions, YesNoOptionsSpanish } from '../../staticResources/InputOptions';
import { ISelfReferralFormFields } from '../../interfaces/ISelfReferralFormFields';
import { useFormikContext } from 'formik';
import { getLabels } from '../../services/LabelService';
import { SelfReferralFormSteps } from '../../staticResources/FormStepsForLabels';
import * as MISC_CONSTANTS from '../../staticResources/MiscConstants';
import * as INPUT_LABEL_KEYS from '../../staticResources/InputLabelKeys';

interface Props {
    isKidney: boolean;
    isDonor: boolean;
    isEnglish: boolean;
    stepNames: Map<number, string>;
}

const ConfirmInformation = ({ isKidney, isDonor, isEnglish, stepNames }: Props) => {
    const { values } = useFormikContext<ISelfReferralFormFields>();
    const labels = getLabels(isEnglish, SelfReferralFormSteps.ConfirmInfo);

    let labelledHeight = '';

    if (values.HeightUnit) {

        if (values.HeightUnit === 'feetInches') {

            labelledHeight = `${values.HeightFeet} ${labels.get(INPUT_LABEL_KEYS.heightFeetLabelKey) ?? ''} `;

            if (values.HeightInches) {
                labelledHeight += `${values.HeightInches} ${labels.get(INPUT_LABEL_KEYS.heightInchesLabelKey) ?? ''}`;
            }
        } else {
            labelledHeight = `${values.HeightCentimeters} ${labels.get(INPUT_LABEL_KEYS.heightCentimetersLabelKey) ?? ''}`;
        }
    }

    return (
        <div style={{ display: 'block' }}>
            <p tabIndex={0} className={MISC_CONSTANTS.confirmPageHeaders}><b>{isEnglish ? "Please confirm the information below" : "Por favor confirme la información a continuación"}</b></p>

            {isDonor &&
                <>
                    <Divider tabIndex={-1} sx={{ borderBottomWidth: 3 }} />
                    <p tabIndex={0} className={MISC_CONSTANTS.confirmPageHeaders}>{stepNames.get(SelfReferralFormSteps.RecipientInfo)}</p>
                    <ul className={MISC_CONSTANTS.confirmPageList}>
                        <li tabIndex={0}>
                            <b>{labels.get(INPUT_LABEL_KEYS.nonDirectedDonorLabelKey) ?? ''}: </b> {isEnglish ? YesNoOptions.get(values.NonDirectedDonor) : YesNoOptionsSpanish.get(values.NonDirectedDonor)}
                        </li>
                        <li tabIndex={0}>
                            <b>{labels.get(INPUT_LABEL_KEYS.recipientNameLabelKey) ?? ''}: </b> {values.RecipientName}
                        </li>
                    </ul>
                </>
            }
            <Divider tabIndex={-1} sx={{ borderBottomWidth: 3 }} />
            <p tabIndex={0} className={MISC_CONSTANTS.confirmPageHeaders}>{stepNames.get(SelfReferralFormSteps.BasicInfo)}</p>
            <ul className={MISC_CONSTANTS.confirmPageList}>
                <li tabIndex={0}>
                    <b>{labels.get(INPUT_LABEL_KEYS.firstNameLabelKey) ?? ''}: </b> {values.FirstName}
                </li>
                <li tabIndex={0}>
                    <b>{labels.get(INPUT_LABEL_KEYS.lastNameLabelKey) ?? ''}: </b> {values.LastName}
                </li>
                <li tabIndex={0}>
                    <b>{labels.get(INPUT_LABEL_KEYS.dateOfBirthLabelKey) ?? ''}: </b> {values.DateOfBirth}
                </li>
                <li tabIndex={0}>
                    <b>{labels.get(INPUT_LABEL_KEYS.birthSexLabelKey) ?? ''}: </b> {BirthSexes.get(values.BirthSex)}
                </li>
            </ul>
            <Divider tabIndex={-1} sx={{ borderBottomWidth: 3 }} />
            <p tabIndex={0} className={MISC_CONSTANTS.confirmPageHeaders}>{stepNames.get(SelfReferralFormSteps.ContactInfo)}</p>
            <ul className={MISC_CONSTANTS.confirmPageList}>
                <li tabIndex={0}>
                    <b>{labels.get(INPUT_LABEL_KEYS.address1LabelKey) ?? ''}: </b> {values.Address1}
                </li>
                <li tabIndex={0}>
                    <b>{labels.get(INPUT_LABEL_KEYS.address2LabelKey) ?? ''}: </b> {values.Address2}
                </li>
                <li tabIndex={0}>
                    <b>{labels.get(INPUT_LABEL_KEYS.cityLabelKey) ?? ''}: </b> {values.City}
                </li>
                <li tabIndex={0}>
                    <b>{labels.get(INPUT_LABEL_KEYS.stateLabelKey) ?? ''}: </b> {States.get(values.State)}
                </li>
                <li tabIndex={0}>
                    <b>{labels.get(INPUT_LABEL_KEYS.zipLabelKey) ?? ''}: </b> {values.Zip}
                </li>
                <li tabIndex={0}>
                    <b>{labels.get(INPUT_LABEL_KEYS.prefContactMethodLabelKey) ?? ''}: </b> {isEnglish ? ContactMethods.get(values.PrefContactMethod) : ContactMethodsSpanish.get(values.PrefContactMethod)}
                </li>
                <li tabIndex={0}>
                    <b>{labels.get(INPUT_LABEL_KEYS.emailLabelKey) ?? ''}: </b> {values.Email}
                </li>
                <li tabIndex={0}>
                    <b>{labels.get(INPUT_LABEL_KEYS.homePhoneLabelKey) ?? ''}: </b> {values.HomePhone}
                </li>
                <li tabIndex={0}>
                    <b>{labels.get(INPUT_LABEL_KEYS.cellPhoneLabelKey) ?? ''}: </b> {values.CellPhone}
                </li>
            </ul>
            <Divider tabIndex={-1} sx={{ borderBottomWidth: 3 }} />
            <p tabIndex={0} className={MISC_CONSTANTS.confirmPageHeaders}>{stepNames.get(SelfReferralFormSteps.HealthInfo)}</p>
            <ul className={MISC_CONSTANTS.confirmPageList}>
                <li tabIndex={0}>
                    <b>{isEnglish ? 'Height' : 'Altura'}: </b> {labelledHeight}
                </li>
                <li tabIndex={0}>
                    <b>{isEnglish ? 'Weight' : 'Peso'}: </b> {`${values.Weight} ${isEnglish ? WeightUnits.get(values.WeightUnit) : WeightUnitsSpanish.get(values.WeightUnit)}`}
                </li>
                {!isDonor && (
                    <>
                        <li tabIndex={0}>
                            <b>{labels.get(INPUT_LABEL_KEYS.physicianNameLabelKey) ?? ''}: </b> {values.PhysicianName}
                        </li>
                        <li tabIndex={0}>
                            <b>{labels.get(INPUT_LABEL_KEYS.numPriorTransplantsLabelKey) ?? ''}: </b> {values.NumPriorTransplants}
                        </li>
                        {isKidney && (
                            <>
                                <li tabIndex={0}>
                                    <b>{labels.get(INPUT_LABEL_KEYS.currentDialysisLabelKey) ?? ''}: </b> {isEnglish ? YesNoOptions.get(values.CurrentDialysis) : YesNoOptionsSpanish.get(values.CurrentDialysis)}
                                </li>
                                {values.CurrentDialysis === 'yes' && (
                                    <>
                                        <li tabIndex={0}>
                                            <b>{labels.get(INPUT_LABEL_KEYS.dialysisCenterNameLabelKey) ?? ''}: </b> {values.DialysisCenterName}
                                        </li>
                                        <li tabIndex={0}>
                                            <b>{labels.get(INPUT_LABEL_KEYS.dialysisCenterCityLabelKey) ?? ''}: </b> {values.DialysisCenterCity}
                                        </li>
                                        <li tabIndex={0}>
                                            <b>{labels.get(INPUT_LABEL_KEYS.dialysisCenterStateLabelKey) ?? ''}: </b> {States.get(values.DialysisCenterState)}
                                        </li>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </ul>
        </div>
    );
}

export default ConfirmInformation
