import * as ENGLISH_LABELS from '../staticResources/EnglishInputLabels';
import * as SPANISH_LABELS from '../staticResources/SpanishInputLabels';
import * as ENGLISH_STEP_NAMES from '../staticResources/EnglishStepNames';
import * as SPANISH_STEP_NAMES from '../staticResources/SpanishStepNames';
import { SelfReferralFormSteps } from '../staticResources/FormStepsForLabels';
import * as INPUT_LABEL_KEYS from '../staticResources/InputLabelKeys';

export const getStepNames = (isEnglish: boolean, isDonor: boolean): Map<number, string> => {
    if (isEnglish) {
        let englishSteps = new Map([
            [SelfReferralFormSteps.HealthInfo, ENGLISH_STEP_NAMES.healthInfo],
            [SelfReferralFormSteps.ContactInfo, ENGLISH_STEP_NAMES.contactInfo],
            [SelfReferralFormSteps.RecipientInfo, ENGLISH_STEP_NAMES.recipientInfo]
        ]);

        if (isDonor) {
            //living donor in english
            englishSteps.set(SelfReferralFormSteps.BasicInfo, "Basic Information");
        } else {
            //self-referral in english
            englishSteps.set(SelfReferralFormSteps.BasicInfo, "Patient Information");
        }

        return englishSteps;
    } else {
        let spanishSteps = new Map([
            [SelfReferralFormSteps.HealthInfo, SPANISH_STEP_NAMES.healthInfo],
            [SelfReferralFormSteps.ContactInfo, SPANISH_STEP_NAMES.contactInfo],
            [SelfReferralFormSteps.RecipientInfo, SPANISH_STEP_NAMES.recipientInfo]
        ]);

        if (isDonor) {
            //living donor in spanish
            spanishSteps.set(SelfReferralFormSteps.BasicInfo, "Información básica");
        } else {
            //self-referral in spanish
            spanishSteps.set(SelfReferralFormSteps.BasicInfo, "Información del paciente");
        }

        return spanishSteps;
    }
}

export const getLabels = (isEnglish: boolean, formStep: number): Map<string, string> => {
    if (isEnglish) {
        switch (formStep) {
            case SelfReferralFormSteps.RecipientInfo: {
                return new Map([
                    [INPUT_LABEL_KEYS.nonDirectedDonorLabelKey, ENGLISH_LABELS.nonDirectedDonor],
                    [INPUT_LABEL_KEYS.recipientNameLabelKey, ENGLISH_LABELS.recipientName]
                ]);
            }
            case SelfReferralFormSteps.BasicInfo: {
                return new Map([
                    [INPUT_LABEL_KEYS.firstNameLabelKey, ENGLISH_LABELS.firstName],
                    [INPUT_LABEL_KEYS.lastNameLabelKey, ENGLISH_LABELS.lastName],
                    [INPUT_LABEL_KEYS.dateOfBirthLabelKey, `${ENGLISH_LABELS.dateOfBirth} (MM/DD/YYYY)`],
                    [INPUT_LABEL_KEYS.birthSexLabelKey, ENGLISH_LABELS.birthSex]
                ]);
            }
            case SelfReferralFormSteps.ContactInfo: {
                return new Map([
                    [INPUT_LABEL_KEYS.address1LabelKey, ENGLISH_LABELS.address1],
                    [INPUT_LABEL_KEYS.address2LabelKey, ENGLISH_LABELS.address2],
                    [INPUT_LABEL_KEYS.cityLabelKey, ENGLISH_LABELS.city],
                    [INPUT_LABEL_KEYS.stateLabelKey, ENGLISH_LABELS.state],
                    [INPUT_LABEL_KEYS.zipLabelKey, ENGLISH_LABELS.zip],
                    [INPUT_LABEL_KEYS.emailLabelKey, ENGLISH_LABELS.email],
                    [INPUT_LABEL_KEYS.homePhoneLabelKey, ENGLISH_LABELS.homePhone],
                    [INPUT_LABEL_KEYS.cellPhoneLabelKey, ENGLISH_LABELS.cellPhone],
                    [INPUT_LABEL_KEYS.prefContactMethodLabelKey, ENGLISH_LABELS.prefContactMethod]
                ]);
            }
            case SelfReferralFormSteps.HealthInfo: {
                return new Map([
                    [INPUT_LABEL_KEYS.heightUnitLabelKey, ENGLISH_LABELS.heightUnit],
                    [INPUT_LABEL_KEYS.weightUnitLabelKey, ENGLISH_LABELS.weightUnit],
                    [INPUT_LABEL_KEYS.heightFeetLabelKey, ENGLISH_LABELS.heightFeet],
                    [INPUT_LABEL_KEYS.heightInchesLabelKey, ENGLISH_LABELS.heightInches],
                    [INPUT_LABEL_KEYS.heightCentimetersLabelKey, ENGLISH_LABELS.heightCentimeters],
                    [INPUT_LABEL_KEYS.weightPoundsLabelKey, ENGLISH_LABELS.weightPounds],
                    [INPUT_LABEL_KEYS.weightKilogramsLabelKey, ENGLISH_LABELS.weightKilograms],
                    [INPUT_LABEL_KEYS.physicianNameLabelKey, ENGLISH_LABELS.physicianName],
                    [INPUT_LABEL_KEYS.numPriorTransplantsLabelKey, ENGLISH_LABELS.numPriorTransplants],
                    [INPUT_LABEL_KEYS.currentDialysisLabelKey, ENGLISH_LABELS.currentDialysis],
                    [INPUT_LABEL_KEYS.dialysisCenterNameLabelKey, ENGLISH_LABELS.dialysisCenterName],
                    [INPUT_LABEL_KEYS.dialysisCenterCityLabelKey, ENGLISH_LABELS.dialysisCenterCity],
                    [INPUT_LABEL_KEYS.dialysisCenterStateLabelKey, ENGLISH_LABELS.dialysisCenterState]
                ]);
            }
            case SelfReferralFormSteps.ConfirmInfo: {
                return new Map([
                    [INPUT_LABEL_KEYS.nonDirectedDonorLabelKey, ENGLISH_LABELS.nonDirectedDonor],
                    [INPUT_LABEL_KEYS.recipientNameLabelKey, ENGLISH_LABELS.recipientName],
                    [INPUT_LABEL_KEYS.firstNameLabelKey, ENGLISH_LABELS.firstName],
                    [INPUT_LABEL_KEYS.lastNameLabelKey, ENGLISH_LABELS.lastName],
                    [INPUT_LABEL_KEYS.dateOfBirthLabelKey, ENGLISH_LABELS.dateOfBirth],
                    [INPUT_LABEL_KEYS.birthSexLabelKey, ENGLISH_LABELS.birthSex],
                    [INPUT_LABEL_KEYS.address1LabelKey, ENGLISH_LABELS.address1],
                    [INPUT_LABEL_KEYS.address2LabelKey, ENGLISH_LABELS.address2],
                    [INPUT_LABEL_KEYS.cityLabelKey, ENGLISH_LABELS.city],
                    [INPUT_LABEL_KEYS.stateLabelKey, ENGLISH_LABELS.state],
                    [INPUT_LABEL_KEYS.zipLabelKey, ENGLISH_LABELS.zip],
                    [INPUT_LABEL_KEYS.emailLabelKey, ENGLISH_LABELS.email],
                    [INPUT_LABEL_KEYS.homePhoneLabelKey, ENGLISH_LABELS.homePhone],
                    [INPUT_LABEL_KEYS.cellPhoneLabelKey, ENGLISH_LABELS.cellPhone],
                    [INPUT_LABEL_KEYS.prefContactMethodLabelKey, ENGLISH_LABELS.prefContactMethod],
                    [INPUT_LABEL_KEYS.heightUnitLabelKey, ENGLISH_LABELS.heightUnit],
                    [INPUT_LABEL_KEYS.weightUnitLabelKey, ENGLISH_LABELS.weightUnit],
                    [INPUT_LABEL_KEYS.heightFeetLabelKey, ENGLISH_LABELS.heightFeet],
                    [INPUT_LABEL_KEYS.heightInchesLabelKey, ENGLISH_LABELS.heightInches],
                    [INPUT_LABEL_KEYS.heightCentimetersLabelKey, ENGLISH_LABELS.heightCentimeters],
                    [INPUT_LABEL_KEYS.weightPoundsLabelKey, ENGLISH_LABELS.weightPounds],
                    [INPUT_LABEL_KEYS.weightKilogramsLabelKey, ENGLISH_LABELS.weightKilograms],
                    [INPUT_LABEL_KEYS.physicianNameLabelKey, ENGLISH_LABELS.physicianName],
                    [INPUT_LABEL_KEYS.numPriorTransplantsLabelKey, ENGLISH_LABELS.numPriorTransplants],
                    [INPUT_LABEL_KEYS.currentDialysisLabelKey, ENGLISH_LABELS.currentDialysis],
                    [INPUT_LABEL_KEYS.dialysisCenterNameLabelKey, ENGLISH_LABELS.dialysisCenterName],
                    [INPUT_LABEL_KEYS.dialysisCenterCityLabelKey, ENGLISH_LABELS.dialysisCenterCity],
                    [INPUT_LABEL_KEYS.dialysisCenterStateLabelKey, ENGLISH_LABELS.dialysisCenterState]
                ]);
            }
            default: {
                return new Map([]);
            }
        }
    } else {
        switch (formStep) {
            case SelfReferralFormSteps.RecipientInfo: {
                return new Map([
                    [INPUT_LABEL_KEYS.nonDirectedDonorLabelKey, SPANISH_LABELS.nonDirectedDonor],
                    [INPUT_LABEL_KEYS.recipientNameLabelKey, SPANISH_LABELS.recipientName]
                ]);
            }
            case SelfReferralFormSteps.BasicInfo: {
                return new Map([
                    [INPUT_LABEL_KEYS.firstNameLabelKey, SPANISH_LABELS.firstName],
                    [INPUT_LABEL_KEYS.lastNameLabelKey, SPANISH_LABELS.lastName],
                    [INPUT_LABEL_KEYS.dateOfBirthLabelKey, `${SPANISH_LABELS.dateOfBirth} (MM/DD/YYYY)`],
                    [INPUT_LABEL_KEYS.birthSexLabelKey, SPANISH_LABELS.birthSex]
                ]);
            }
            case SelfReferralFormSteps.ContactInfo: {
                return new Map([
                    [INPUT_LABEL_KEYS.address1LabelKey, SPANISH_LABELS.address1],
                    [INPUT_LABEL_KEYS.address2LabelKey, SPANISH_LABELS.address2],
                    [INPUT_LABEL_KEYS.cityLabelKey, SPANISH_LABELS.city],
                    [INPUT_LABEL_KEYS.stateLabelKey, SPANISH_LABELS.state],
                    [INPUT_LABEL_KEYS.zipLabelKey, SPANISH_LABELS.zip],
                    [INPUT_LABEL_KEYS.emailLabelKey, SPANISH_LABELS.email],
                    [INPUT_LABEL_KEYS.homePhoneLabelKey, SPANISH_LABELS.homePhone],
                    [INPUT_LABEL_KEYS.cellPhoneLabelKey, SPANISH_LABELS.cellPhone],
                    [INPUT_LABEL_KEYS.prefContactMethodLabelKey, SPANISH_LABELS.prefContactMethod]
                ]);
            }
            case SelfReferralFormSteps.HealthInfo: {
                return new Map([
                    [INPUT_LABEL_KEYS.heightUnitLabelKey, SPANISH_LABELS.heightUnit],
                    [INPUT_LABEL_KEYS.weightUnitLabelKey, SPANISH_LABELS.weightUnit],
                    [INPUT_LABEL_KEYS.heightFeetLabelKey, SPANISH_LABELS.heightFeet],
                    [INPUT_LABEL_KEYS.heightInchesLabelKey, SPANISH_LABELS.heightInches],
                    [INPUT_LABEL_KEYS.heightCentimetersLabelKey, SPANISH_LABELS.heightCentimeters],
                    [INPUT_LABEL_KEYS.weightPoundsLabelKey, SPANISH_LABELS.weightPounds],
                    [INPUT_LABEL_KEYS.weightKilogramsLabelKey, SPANISH_LABELS.weightKilograms],
                    [INPUT_LABEL_KEYS.physicianNameLabelKey, SPANISH_LABELS.physicianName],
                    [INPUT_LABEL_KEYS.numPriorTransplantsLabelKey, SPANISH_LABELS.numPriorTransplants],
                    [INPUT_LABEL_KEYS.currentDialysisLabelKey, SPANISH_LABELS.currentDialysis],
                    [INPUT_LABEL_KEYS.dialysisCenterNameLabelKey, SPANISH_LABELS.dialysisCenterName],
                    [INPUT_LABEL_KEYS.dialysisCenterCityLabelKey, SPANISH_LABELS.dialysisCenterCity],
                    [INPUT_LABEL_KEYS.dialysisCenterStateLabelKey, SPANISH_LABELS.dialysisCenterState]
                ]);
            }
            case SelfReferralFormSteps.ConfirmInfo: {
                return new Map([
                    [INPUT_LABEL_KEYS.nonDirectedDonorLabelKey, SPANISH_LABELS.nonDirectedDonor],
                    [INPUT_LABEL_KEYS.recipientNameLabelKey, SPANISH_LABELS.recipientName],
                    [INPUT_LABEL_KEYS.firstNameLabelKey, SPANISH_LABELS.firstName],
                    [INPUT_LABEL_KEYS.lastNameLabelKey, SPANISH_LABELS.lastName],
                    [INPUT_LABEL_KEYS.dateOfBirthLabelKey, SPANISH_LABELS.dateOfBirth],
                    [INPUT_LABEL_KEYS.birthSexLabelKey, SPANISH_LABELS.birthSex],
                    [INPUT_LABEL_KEYS.address1LabelKey, SPANISH_LABELS.address1],
                    [INPUT_LABEL_KEYS.address2LabelKey, SPANISH_LABELS.address2],
                    [INPUT_LABEL_KEYS.cityLabelKey, SPANISH_LABELS.city],
                    [INPUT_LABEL_KEYS.stateLabelKey, SPANISH_LABELS.state],
                    [INPUT_LABEL_KEYS.zipLabelKey, SPANISH_LABELS.zip],
                    [INPUT_LABEL_KEYS.emailLabelKey, SPANISH_LABELS.email],
                    [INPUT_LABEL_KEYS.homePhoneLabelKey, SPANISH_LABELS.homePhone],
                    [INPUT_LABEL_KEYS.cellPhoneLabelKey, SPANISH_LABELS.cellPhone],
                    [INPUT_LABEL_KEYS.prefContactMethodLabelKey, SPANISH_LABELS.prefContactMethod],
                    [INPUT_LABEL_KEYS.heightUnitLabelKey, SPANISH_LABELS.heightUnit],
                    [INPUT_LABEL_KEYS.weightUnitLabelKey, SPANISH_LABELS.weightUnit],
                    [INPUT_LABEL_KEYS.heightFeetLabelKey, SPANISH_LABELS.heightFeet],
                    [INPUT_LABEL_KEYS.heightInchesLabelKey, SPANISH_LABELS.heightInches],
                    [INPUT_LABEL_KEYS.heightCentimetersLabelKey, SPANISH_LABELS.heightCentimeters],
                    [INPUT_LABEL_KEYS.weightPoundsLabelKey, SPANISH_LABELS.weightPounds],
                    [INPUT_LABEL_KEYS.weightKilogramsLabelKey, SPANISH_LABELS.weightKilograms],
                    [INPUT_LABEL_KEYS.physicianNameLabelKey, SPANISH_LABELS.physicianName],
                    [INPUT_LABEL_KEYS.numPriorTransplantsLabelKey, SPANISH_LABELS.numPriorTransplants],
                    [INPUT_LABEL_KEYS.currentDialysisLabelKey, SPANISH_LABELS.currentDialysis],
                    [INPUT_LABEL_KEYS.dialysisCenterNameLabelKey, SPANISH_LABELS.dialysisCenterName],
                    [INPUT_LABEL_KEYS.dialysisCenterCityLabelKey, SPANISH_LABELS.dialysisCenterCity],
                    [INPUT_LABEL_KEYS.dialysisCenterStateLabelKey, SPANISH_LABELS.dialysisCenterState]
                ]);
            }
            default: {
                return new Map([]);
            }
        }
    }
}