import { Formik, FormikConfig, FormikHelpers, FormikValues, Form } from 'formik';
import React, { useState } from 'react';
import FormNavigation from './shared/FormNavigation';
import { Divider } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { PostReferral } from '../../services/HttpService';
import { ISelfReferralFormFields } from '../../interfaces/ISelfReferralFormFields';
import LoadingSpinner from './shared/LoadingSpinner';
import * as MISC_CONSTANTS from '../../staticResources/MiscConstants';

interface Props extends FormikConfig<FormikValues> {
    children: React.ReactNode;
    recipientName: string;
    programId: string;
    initialValues: ISelfReferralFormFields;
    setConfirmationCode: (confirmationCode: string) => void;
    //nullable to make the code happy but should always be present in practice
    contactContent?: string;
}

const SelfReferralForm = ({ setConfirmationCode, recipientName, programId, children, initialValues, contactContent }: Props) => {
    const [stepNumber, setStepNumber] = useState(0);
    const [snapshot, setSnapshot] = useState<ISelfReferralFormFields>(initialValues);
    const [submitLoading, setSubmitLoading] = useState(false);

    const steps = React.Children.toArray(children) as React.ReactElement[];
    const step = steps[stepNumber];
    const totalSteps = steps.length;
    const isLastStep = stepNumber === totalSteps - 1;

    const reCaptchaRef = React.createRef<ReCAPTCHA>();

    const next = (values: ISelfReferralFormFields) => {
        setSnapshot(values);
        setStepNumber(stepNumber + 1);
    }

    const previous = (values: ISelfReferralFormFields) => {
        setSnapshot(values);
        setStepNumber(stepNumber - 1);
    }

    const handleSubmit = async (values: ISelfReferralFormFields, actions: FormikHelpers<ISelfReferralFormFields>) => {

        if (step.props.onSubmit) {
            await step.props.onSubmit(values);
        }

        //ConfirmInformation is where we actually POST to txaccess
        if (step.props.stepName === MISC_CONSTANTS.confirmInformation) {
            const submitResponse = await PostReferral(programId, reCaptchaRef, setSubmitLoading, values);

            if (submitResponse.ConfirmationCode) {
                setConfirmationCode(submitResponse.ConfirmationCode);
                setSubmitLoading(false);
                actions.setTouched({});
                next(values);
            } else {
                setSubmitLoading(false);
                alert(submitResponse.ErrorMessage);
            }
        } else {
            actions.setTouched({});
            next(values);
        }
    }

    const pageHeader = (step.props.stepName !== MISC_CONSTANTS.confirmInformation ? (stepNumber > 0 ? <p tabIndex={0} className="commonPageHeader">{step.props.stepName}</p>
        : <p tabIndex={0} className="firstPageHeader">Please select a language<br /><span style={{ color: "gray" }}>Por favor, seleccione un idioma</span></p>)
        : <p tabIndex={0} className="commonPageHeader"></p>);
    return (
        <div>
            {step.props.centerPageContent &&
                <>
                    <Divider sx={{ borderBottomWidth: 3 }} />
                    {/* Center's page content needs to be centered on the first page to match the alignment of the page */}
                    {stepNumber > 0 ?
                        <p tabIndex={0}>{step.props.centerPageContent}</p>
                        : <p tabIndex={0} className="centerText">{step.props.centerPageContent}</p>
                    }
                </>
            }
            <Divider sx={{ borderBottomWidth: 3 }} />
            {pageHeader}
            <Formik
                initialValues={snapshot}
                onSubmit={(values: ISelfReferralFormFields, actions: FormikHelpers<ISelfReferralFormFields>) => handleSubmit(values, actions)}
                validationSchema={step.props.validationSchema}
            >
                {(formik) => (
                    <Form> {/* This is Formik's Form component that automatically maps the onSubmit from the parent Formik component */}
                        {step} {/* This is the actual child component (ex. BasicInformation) called inside FormStep in App.tsx*/}
                        {!isLastStep &&
                            <FormNavigation
                                isLastStep={isLastStep}
                                stepName={step.props.stepName}
                                hasPrevious={stepNumber > 0}
                                recipientName={recipientName}
                                programId={programId}
                                onBackClick={() => previous(formik.values)}
                                reCaptchaRef={reCaptchaRef}
                                contactContent={contactContent ?? ''}
                            />
                        }
                    </Form>
                )}
            </Formik>

            {/* Loading Spinner */}
            <LoadingSpinner
                isLoading={submitLoading}
            />
        </div>
    );
}

export default SelfReferralForm

export const FormStep = ({ stepName = '', centerPageContent = '', children }: any) => children;