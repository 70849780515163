import InputRadioGroup from '../inputs/InputRadioGroup';
import { Languages } from '../../staticResources/InputOptions';

interface Props {
    //this is for taking the setUseLanguage hook from App.tsx and passing it down to InputRadioGroup where it is actually called
    setUserLanguage: (Language: string) => void;
}

const LandingAndLanguage = ({ ...props }: Props) => {
    return (
        <div className="centerText">
            <br />
            <InputRadioGroup
                setUserLanguage={props.setUserLanguage}
                name="Language"
                label=''
                options={Languages}
            />
        </div>
    );
}

export default LandingAndLanguage