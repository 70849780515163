import { Box, CircularProgress, Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';


interface Props {
    isLoading: boolean;
}

const LoadingSpinner = ({ isLoading }: Props) => {

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    return (
        <BootstrapDialog
            open={isLoading}
            aria-label="Loading"
        >
            <DialogContent>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            </DialogContent>
        </BootstrapDialog>
    );
}

export default LoadingSpinner;
