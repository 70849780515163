import { Box, Typography } from "@mui/material";

interface Props {
    errorMessage?: string;
}

const ErrorScreen = ({ errorMessage }: Props) => {
    return (
        <>
            <Box className="errorBox">
                <Typography variant="h3" style={{ color: 'red' }}>
                    {/* Default to a 404 if no specific errorMessage prop is provided */}
                    {errorMessage ? errorMessage : "404 - Page not found (Página no encontrada)"}
                </Typography>
            </Box>
        </>
    );
}

export default ErrorScreen