import { ISelfReferralFormFields } from "../interfaces/ISelfReferralFormFields";
import * as MISC_CONSTANTS from "../staticResources/MiscConstants";

const SelfReferralInitialValues = (recipientName?: string) => {
    const nonDirected = recipientName ? 'no' : '';
    const initValues: ISelfReferralFormFields = {
        FirstName: '',
        LastName: '',
        DateOfBirth: '',
        Language: MISC_CONSTANTS.english,
        BirthSex: '',
        Address1: '',
        Address2: '',
        City: '',
        State: '',
        Zip: '',
        Email: '',
        HomePhone: '',
        CellPhone: '',
        PrefContactMethod: '',
        HeightUnit: '',
        WeightUnit: '',
        HeightFeet: '',
        HeightInches: '',
        HeightCentimeters: '',
        Weight: '',
        PhysicianName: '',
        NumPriorTransplants: '',
        CurrentDialysis: '',
        DialysisCenterName: '',
        DialysisCenterState: '',
        DialysisCenterCity: '',
        NonDirectedDonor: nonDirected,
        RecipientName: recipientName ?? '',
        ProgramID: ''
    };

    return initValues;
}

export default SelfReferralInitialValues