import { ITransplantProgramInfo } from "../interfaces/ITransplantProgramInfo";

const TransplantProgramInitialValues = () => {
    const initValues: ITransplantProgramInfo = {
        CenterName: "",
        Organ: "",
        CenterImage: "",
        ReferralType: "",
        EnglishPageContent: {},
        SpanishPageContent: {},
    };

    return initValues;
}

export default TransplantProgramInitialValues