export const States = new Map([
    ["AL", "Alabama"],
    ["AK", "Alaska"],
    ["AZ", "Arizona"],
    ["AR", "Arkansas"],
    ["CA", "California"],
    ["CO", "Colorado"],
    ["CT", "Connecticut"],
    ["DE", "Delaware"],
    ["FL", "Florida"],
    ["GA", "Georgia"],
    ["ID", "Idaho"],
    ["HI", "Hawaii"],
    ["IL", "Illinois"],
    ["IN", "Indiana"],
    ["IA", "Iowa"],
    ["KS", "Kansas"],
    ["KY", "Kentucky"],
    ["LA", "Louisiana"],
    ["ME", "Maine"],
    ["MD", "Maryland"],
    ["MA", "Massachusetts"],
    ["MI", "Michigan"],
    ["MN", "Minnesota"],
    ["MS", "Mississippi"],
    ["MO", "Missouri"],
    ["MT", "Montana"],
    ["NE", "Nebraska"],
    ["NV", "Nevada"],
    ["NH", "New Hampshire"],
    ["NJ", "New Jersey"],
    ["NM", "New Mexico"],
    ["NY", "New York"],
    ["NC", "North Carolina"],
    ["ND", "North Dakota"],
    ["OH", "Ohio"],
    ["OK", "Oklahoma"],
    ["OR", "Oregon"],
    ["PA", "Pennsylvania"],
    ["RI", "Rhode Island"],
    ["SC", "South Carolina"],
    ["SD", "South Dakota"],
    ["TN", "Tennessee"],
    ["TX", "Texas"],
    ["UT", "Utah"],
    ["VT", "Vermont"],
    ["VA", "Virginia"],
    ["WA", "Washington"],
    ["WV", "West Virginia"],
    ["WI", "Wisconsin"],
    ["WY", "Wyoming"],
    ["DC", "Washington D.C."],
    ["PR", "Puerto Rico"]
]);

export const ContactMethods = new Map([
    ['Email', 'Email'],
    ['HomePhone', 'Home Phone'],
    ['CellPhone', 'Cell Phone']
]);

export const ContactMethodsSpanish = new Map([
    ['Email', 'Correo electrónico'],
    ['HomePhone', 'Teléfono de casa'],
    ['CellPhone', 'Teléfono móvil']
]);

export const Languages = new Map([
    ['english', 'English'],
    ['spanish', 'Español']
]);

export const BirthSexes = new Map([
    ['female', 'Female'],
    ['male', 'Male']
]);

export const BirthSexesSpanish = new Map([
    ['female', 'Femenina'],
    ['male', 'Masculino']
]);

export const HeightUnits = new Map([
    ['feetInches', 'ft/in'],
    ['centimeters', 'cm']
]);

export const HeightUnitsSpanish = new Map([
    ['feetInches', 'pies/pulgadas'],
    ['centimeters', 'centímetros']
]);

export const WeightUnits = new Map([
    ['pounds', 'lb'],
    ['kilograms', 'kg']
]);

export const WeightUnitsSpanish = new Map([
    ['pounds', 'libras'],
    ['kilograms', 'kilogramos']
]);

export const YesNoOptions = new Map([
    ['yes', 'Yes'],
    ['no', 'No']
]);

export const YesNoOptionsSpanish = new Map([
    ['yes', 'Sí'],
    ['no', 'No']
]);