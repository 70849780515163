export const nonDirectedDonorLabelKey = "nonDirectedDonorLabel";
export const recipientNameLabelKey = "recipientNameLabel";
export const firstNameLabelKey = "firstNameLabel";
export const lastNameLabelKey = "lastNameLabel";
export const dateOfBirthLabelKey = "dateOfBirthLabel";
export const birthSexLabelKey = "birthSexLabel";
export const address1LabelKey = "address1Label";
export const address2LabelKey = "address2Label";
export const cityLabelKey = "cityLabel";
export const stateLabelKey = "stateLabel";
export const zipLabelKey = "zipLabel";
export const emailLabelKey = "emailLabel";
export const homePhoneLabelKey = "homePhoneLabel";
export const cellPhoneLabelKey = "cellPhoneLabel";
export const prefContactMethodLabelKey = "prefContactMethodLabel";
export const heightUnitLabelKey = "heightUnitLabel";
export const weightUnitLabelKey = "weightUnitLabel";
export const heightFeetLabelKey = "heightFeetLabel";
export const heightInchesLabelKey = "heightInchesLabel";
export const heightCentimetersLabelKey = "heightCentimetersLabel";
export const weightPoundsLabelKey = "weightPoundsLabel";
export const weightKilogramsLabelKey = "weightKilogramsLabel";
export const physicianNameLabelKey = "physicianNameLabel";
export const numPriorTransplantsLabelKey = "numPriorTransplantsLabel";
export const currentDialysisLabelKey = "currentDialysisLabel";
export const dialysisCenterNameLabelKey = "dialysisCenterNameLabel";
export const dialysisCenterCityLabelKey = "dialysisCenterCityLabel";
export const dialysisCenterStateLabelKey = "dialysisCenterStateLabel";