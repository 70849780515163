//Basic Information
export const firstName = "Nombre de pila";
export const lastName = "Apellido";
export const dateOfBirth = "Fecha de nacimiento";
export const birthSex = "Sexo de nacimiento";

//Recipient Information
export const nonDirectedDonor = "Estoy dispuesto a donar a cualquier destinatario";
export const recipientName = "Nombre del destinatario preferido";

//Contact Information
export const address1 = "Dirección Línea 1";
export const address2 = "Línea de dirección 2";
export const city = "Ciudad";
export const state = "Estado";
export const zip = "Código postal";
export const email = "Correo electrónico";
export const homePhone = "Teléfono de casa";
export const cellPhone = "Teléfono móvil";
export const prefContactMethod = "Método de Contacto Preferido";

//Health Information
export const heightUnit = "Unidad de altura";
export const weightUnit = "Unidad de peso";
export const heightFeet = "Pies";
export const heightInches = "Pulgadas";
export const heightCentimeters = "Centímetros";
export const weightPounds = "Libras";
export const weightKilograms = "Kilogramos";
export const physicianName = "Nombre del médico";
export const numPriorTransplants = "Número de trasplantes previos";
export const currentDialysis = "¿Está usted actualmente en diálisis?";
export const dialysisCenterName = "Nombre del centro de diálisis";
export const dialysisCenterCity = "Centro de diálisis de la ciudad";
export const dialysisCenterState = "Estado del centro de diálisis";