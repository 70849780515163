import InputTextField from '../inputs/InputTextField';
import InputSelectField from '../inputs/InputSelectField';
import { Grid } from '@mui/material';
import { BirthSexes, BirthSexesSpanish } from '../../staticResources/InputOptions';
import { SelfReferralFormSteps } from '../../staticResources/FormStepsForLabels';
import { getLabels } from '../../services/LabelService';
import * as INPUT_LABEL_KEYS from '../../staticResources/InputLabelKeys';

interface Props {
    isEnglish: boolean;
}

const BasicInformation = ({ isEnglish }: Props) => {
    const labels = getLabels(isEnglish, SelfReferralFormSteps.BasicInfo);

    return (
        <Grid container spacing={1}>
            <Grid item md={3} sm={12} xs={12} lg={3}>
                <InputTextField
                    name="FirstName"
                    label={labels.get(INPUT_LABEL_KEYS.firstNameLabelKey) ?? ''}
                />
            </Grid>
            <Grid item md={3} sm={12} xs={12} lg={3}>
                <InputTextField
                    name="LastName"
                    label={labels.get(INPUT_LABEL_KEYS.lastNameLabelKey) ?? ''}
                />
            </Grid>
            <Grid item md={3} sm={12} xs={12} lg={3}>
                <InputTextField
                    name="DateOfBirth"
                    isEnglish={isEnglish}
                    label={labels.get(INPUT_LABEL_KEYS.dateOfBirthLabelKey) ?? ''}
                />
            </Grid>
            <Grid item md={3} sm={12} xs={12} lg={3}>
                <InputSelectField
                    name="BirthSex"
                    label={labels.get(INPUT_LABEL_KEYS.birthSexLabelKey) ?? ''}
                    options={isEnglish ? BirthSexes : BirthSexesSpanish}
                />
            </Grid>
        </Grid>
    );
}

export default BasicInformation