import * as yup from 'yup';
import { parse, isDate, subYears } from 'date-fns'

const dateTransform = (originalValue: any) => {
    const parsedDate = isDate(originalValue)
        ? originalValue
        : parse(originalValue, "MM/dd/yyyy", new Date());

    return parsedDate;
};

const numericErrorEnglish = "Please enter a valid numeric value";
const numericErrorSpanish = "Por favor ingresa un valor numérico válido";
const validDobErrorEnglish = "Please enter a valid Date of Birth";
const validDobErrorSpanish = "Por favor ingresa una fecha de nacimiento valida";
const digitErrorEnglish10 = "Must be exactly 10 digits";
const digitErrorSpanish10 = "Debe tener exactamente 10 dígitos";
const digitErrorEnglish5 = "Must be exactly 5 digits";
const digitErrorSpanish5 = "Debe tener exactamente 5 dígitos";
const numOnlyErrorEnglish = "Numbers only";
const numOnlyErrorSpanish = "Solo numeros";

export const LandingAndLanguageValidation = yup.object({
    Language: yup.string().required("Language Selection is required\nSe requiere selección de idioma")
});

export const BasicInformationValidation = (isEnglish: boolean) => yup.object({
    FirstName: yup.string().required(isEnglish ? "First Name is required" : "Se requiere el primer nombre"),
    LastName: yup.string().required(isEnglish ? "Last Name is required" : "El apellido es obligatorio"),
    DateOfBirth: yup.date().transform(dateTransform)
        .required(isEnglish ? "Date of Birth is required" : "Se requiere fecha de nacimiento")
        .max(new Date(), isEnglish ? validDobErrorEnglish : validDobErrorSpanish)
        .min(subYears(new Date(), 100), isEnglish ? validDobErrorEnglish : validDobErrorSpanish)
        .typeError(isEnglish ? validDobErrorEnglish : validDobErrorSpanish),
    BirthSex: yup.string().required(isEnglish ? "Birth Sex is required" : "Se requiere sexo de nacimiento")
});

export const ContactInformationValidation = (isEnglish: boolean) => yup.object({
    Address1: yup.string().required(isEnglish ? "Address Line 1 is required" : "La línea de dirección 1 es obligatoria"),
    City: yup.string().required(isEnglish ? "City is required" : "Se requiere ciudad"),
    State: yup.string().required(isEnglish ? "State is required" : "Se requiere estado"),
    Zip: yup.string().matches(/^[0-9]+$/, isEnglish ? numOnlyErrorEnglish : numOnlyErrorSpanish).required(isEnglish ? "Zip Code is required" : "Se requiere código postal")
        .min(5, isEnglish ? digitErrorEnglish5 : digitErrorSpanish5)
        .max(5, isEnglish ? digitErrorEnglish5 : digitErrorSpanish5),
    PrefContactMethod: yup.string().required(isEnglish ? "Preferred Contact Method is required" : "Se requiere el método de contacto preferido"),
    Email: yup.string().email(isEnglish ? "Please enter a valid email" : "Por favor introduzca una dirección de correo electrónico válida").when('PrefContactMethod', {
        is: 'Email',
        then: (schema) => schema.required(isEnglish ? "Email Address is required" : "Se requiere Dirección de correo electrónico")
    }),
    HomePhone: yup.string().matches(/^[0-9]+$/, isEnglish ? numOnlyErrorEnglish : numOnlyErrorSpanish)
        .min(10, isEnglish ? digitErrorEnglish10 : digitErrorSpanish10)
        .max(10, isEnglish ? digitErrorEnglish10 : digitErrorSpanish10)
        .when('PrefContactMethod', {
            is: 'HomePhone',
            then: (schema) => schema.required(isEnglish ? "10 digit Home Phone is required" : "Se requiere teléfono residencial de 10 dígitos")
        }),
    CellPhone: yup.string().matches(/^[0-9]+$/, isEnglish ? numOnlyErrorEnglish : numOnlyErrorSpanish)
        .min(10, isEnglish ? digitErrorEnglish10 : digitErrorSpanish10)
        .max(10, isEnglish ? digitErrorEnglish10 : digitErrorSpanish10)
        .when('PrefContactMethod', {
            is: 'CellPhone',
            then: (schema) => schema.required(isEnglish ? "10 digit Cell Phone is required" : "Se requiere celular de 10 dígitos")
        })
});

export const DonorHealthInformationValidation = (isEnglish: boolean) => yup.object({
    HeightUnit: yup.string().required(isEnglish ? "Height Unit is required" : "Se requiere unidad de altura"),
    WeightUnit: yup.string().required(isEnglish ? "Weight Unit is required" : "Se requiere unidad de peso"),
    HeightFeet: yup.number().typeError(isEnglish ? numericErrorEnglish : numericErrorSpanish).when('HeightUnit', {
        is: 'feetInches',
        then: (schema) => schema.required(isEnglish ? "Feet are required" : "Se requieren pies")
            .min(2, isEnglish ? numericErrorEnglish : numericErrorSpanish)
            .max(8, isEnglish ? numericErrorEnglish : numericErrorSpanish)
    }),
    HeightInches: yup.number().typeError(isEnglish ? numericErrorEnglish : numericErrorSpanish).when('HeightUnit', {
        is: 'feetInches',
        then: (schema) => schema.required(isEnglish ? "Inches are required (enter 0 for none)" : "Se requieren pulgadas (ingrese 0 para ninguno)")
            .min(0, isEnglish ? numericErrorEnglish : numericErrorSpanish)
            .max(11, isEnglish ? numericErrorEnglish : numericErrorSpanish)
    }),
    HeightCentimeters: yup.number().typeError(isEnglish ? numericErrorEnglish : numericErrorSpanish).when('HeightUnit', {
        is: 'centimeters',
        then: (schema) => schema.required(isEnglish ? "Centimeters are required" : "Se requieren centímetros")
            .min(0, isEnglish ? numericErrorEnglish : numericErrorSpanish)
            .max(230, isEnglish ? numericErrorEnglish : numericErrorSpanish)
    }),
    Weight: yup.number().typeError(isEnglish ? numericErrorEnglish : numericErrorSpanish).required(isEnglish ? "Weight is required" : "Se requiere peso")
        .min(4, isEnglish ? numericErrorEnglish : numericErrorSpanish)
        .max(600, isEnglish ? numericErrorEnglish : numericErrorSpanish),
});

export const HealthInformationValidation = (isKidney: boolean, isEnglish: boolean) => yup.object({
    HeightUnit: yup.string().required(isEnglish ? "Height Unit is required" : "Se requiere unidad de altura"),
    WeightUnit: yup.string().required(isEnglish ? "Weight Unit is required" : "Se requiere unidad de peso"),
    HeightFeet: yup.number().typeError(isEnglish ? numericErrorEnglish : numericErrorSpanish).when('HeightUnit', {
        is: 'feetInches',
        then: (schema) => schema.required(isEnglish ? "Feet are required" : "Se requieren pies")
            .min(2, isEnglish ? numericErrorEnglish : numericErrorSpanish)
            .max(8, isEnglish ? numericErrorEnglish : numericErrorSpanish)
    }),
    HeightInches: yup.number().typeError(isEnglish ? numericErrorEnglish : numericErrorSpanish).when('HeightUnit', {
        is: 'feetInches',
        then: (schema) => schema.required(isEnglish ? "Inches are required (enter 0 for none)" : "Se requieren pulgadas (ingrese 0 para ninguno)")
            .min(0, isEnglish ? numericErrorEnglish : numericErrorSpanish)
            .max(11, isEnglish ? numericErrorEnglish : numericErrorSpanish)
    }),
    HeightCentimeters: yup.number().typeError(isEnglish ? numericErrorEnglish : numericErrorSpanish).when('HeightUnit', {
        is: 'centimeters',
        then: (schema) => schema.required(isEnglish ? "Centimeters are required" : "Se requieren centímetros")
            .min(0, isEnglish ? numericErrorEnglish : numericErrorSpanish)
            .max(230, isEnglish ? numericErrorEnglish : numericErrorSpanish)
    }),
    Weight: yup.number().typeError(isEnglish ? numericErrorEnglish : numericErrorSpanish).required(isEnglish ? "Weight is required" : "Se requiere peso")
        .min(4, isEnglish ? numericErrorEnglish : numericErrorSpanish)
        .max(600, isEnglish ? numericErrorEnglish : numericErrorSpanish),
    PhysicianName: yup.string().required(isEnglish ? "Physician Name is required" : "El nombre del médico es obligatorio"),
    NumPriorTransplants: yup.number().typeError(isEnglish ? numericErrorEnglish : numericErrorSpanish).required(isEnglish ? "# of Prior Transplants is required (enter 0 for none)" : "Se requiere la cantidad de trasplantes previos (ingrese 0 para ninguno)")
        .min(0, isEnglish ? numericErrorEnglish : numericErrorSpanish)
        .max(9, isEnglish ? numericErrorEnglish : numericErrorSpanish),
    CurrentDialysis: yup.string().when([], {
        is: () => isKidney === true,
        then: (schema) => schema.required(isEnglish ? "Required" : "Requerido")
    }),
    DialysisCenterName: yup.string().when('CurrentDialysis', {
        is: 'yes',
        then: (schema) => schema.required(isEnglish ? "Dialysis Center Name is required" : "El nombre del centro de diálisis es obligatorio")
    }),
    DialysisCenterCity: yup.string().when('CurrentDialysis', {
        is: 'yes',
        then: (schema) => schema.required(isEnglish ? "Dialysis Center City is required" : "Se requiere centro de diálisis ciudad")
    }),
    DialysisCenterState: yup.string().when('CurrentDialysis', {
        is: 'yes',
        then: (schema) => schema.required(isEnglish ? "Dialysis Center State is required" : "Se requiere Estado del Centro de Diálisis")
    }),
});

export const RecipientInformationValidation = (isEnglish: boolean) => yup.object({
    NonDirectedDonor: yup.string().required(isEnglish ? "Required" : "Requerido"),
    RecipientName: yup.string().when('NonDirectedDonor', {
        is: 'no',
        then: (schema) => schema.required(isEnglish ? "Recipient Name is required" : "El nombre del destinatario es obligatorio")
    }),
})