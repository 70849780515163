import './App.css';
import SelfReferralForm, { FormStep } from './components/parentForms/SelfReferralForm';
import ErrorScreen from './components/parentForms/shared/ErrorScreen';
import { Box } from '@mui/material';
import BasicInformation from './components/formSteps/BasicInformation';
import LandingAndLanguage from './components/formSteps/LandingAndLanguage';
import CenterInformation from './components/formSteps/CenterInformation';
import ContactInformation from './components/formSteps/ContactInformation';
import { BasicInformationValidation, ContactInformationValidation, DonorHealthInformationValidation, HealthInformationValidation, LandingAndLanguageValidation, RecipientInformationValidation } from './validation/SelfReferralYupObjects';
import HealthInformation from './components/formSteps/HealthInformation';
import SelfReferralInitialValues from './initialValues/SelfReferralInitialValues';
import ConfirmInformation from './components/formSteps/ConfirmInformation';
import { GetTransplantProgramInfo } from './services/HttpService';
import { useEffect, useState } from 'react';
import { ITransplantProgramInfo } from './interfaces/ITransplantProgramInfo';
import TransplantProgramInitialValues from './initialValues/TransplantProgramInitialValues';
import SubmissionConfirmation from './components/formSteps/SubmissionConfirmation';
import LoadingSpinner from './components/parentForms/shared/LoadingSpinner';
import * as MISC_CONSTANTS from './staticResources/MiscConstants';
import RecipientInformation from './components/formSteps/RecipientInformation';
import { getStepNames } from './services/LabelService';
import { SelfReferralFormSteps } from './staticResources/FormStepsForLabels';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

function App() {
    console.log(process.env);
    console.log("instrumentation:" + process.env.REACT_APP_AppInsightsKey);
    var appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: process.env.REACT_APP_AppInsightsKey,
        }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();

    appInsights.trackTrace({message: "this is a level 5 informational trace from self-referral", severityLevel: SeverityLevel.Information});


    const queryParameters = new URLSearchParams(window.location.search);
    const programId = queryParameters.get("pid");

    const [isLoading, setLoading] = useState(true);
    const [errorText, setErrorText] = useState('');
    const [programData, setProgramData] = useState<ITransplantProgramInfo>(TransplantProgramInitialValues);
    //default to english until changed, Language is capitalized to match all the other places its used for the fields/values
    const [Language, setUserLanguage] = useState(MISC_CONSTANTS.english);
    //setConfirmationCode used in FormNavigation.tsx
    const [confirmationCode, setConfirmationCode] = useState('');

    const isEnglish = Language === MISC_CONSTANTS.english;

    //get the tx center, program, and recipient information
    useEffect(() => {
        if (programId) {
            GetTransplantProgramInfo(programId, setErrorText, setLoading, setProgramData, appInsights);
        }
    }, [programId]);


    /* Conditionals MUST live below 'useEffect' bc you will get an error 
    about useEffect being in a conditional otherwise. */

    //if we don't at least have program ID, return an error page (defaults to 404 message)
    if (!programId) {
        return (
            <ErrorScreen />
        );
    }

    //if our intial GET request fails, return an error page
    if (errorText) {
        return (
            <ErrorScreen
                errorMessage={errorText}
            />
        );
    }


    const recipientName = programData.RecipientName ?? '';
    const isDonor = programData.ReferralType === 'donor';
    const initialValues = SelfReferralInitialValues(recipientName);

    //this is used for conditional display and validation of fields that are only relevant to kidney pts
    const isKidney = programData.Organ === 'kidney';

    let displayInfoPage = false;
    //Don't render info page if the center hasn't set content for it in the matching language as the page is only for displaying this content.
    if ((isEnglish && programData.EnglishPageContent.InformationalPage)
        || (!isEnglish && programData.SpanishPageContent.InformationalPage)) {
        displayInfoPage = true;
    }


    //will display spinner if for some reason the initial GET call takes a bit
    if (isLoading) {
        return (
            <LoadingSpinner
                isLoading={isLoading}
            />
        );
    }

    const stepNames = getStepNames(isEnglish, isDonor);

    return (
        <div className="App">
            <header tabIndex={-1} className="App-header">
                {/* data:image/png confirmed to work with images that are png or jpeg */}
                <Box
                    component="img"
                    className="headerImage"
                    alt="Transplant Center Logo"
                    src={`data:image/png;base64,${programData.CenterImage}`}
                />
            </header>
            <SelfReferralForm
                initialValues={initialValues}
                onSubmit={() => { }}
                recipientName={recipientName}
                programId={programId ?? ''}
                setConfirmationCode={setConfirmationCode}
                contactContent={isEnglish ? programData.EnglishPageContent.CenterContact : programData.SpanishPageContent.CenterContact}
            >
                <FormStep
                    stepName=""
                    onSubmit={() => console.log("step 1")}
                    validationSchema={LandingAndLanguageValidation}
                    centerPageContent={isEnglish ? programData.EnglishPageContent.LandingAndLanguage : programData.SpanishPageContent.LandingAndLanguage}
                >
                    <LandingAndLanguage
                        setUserLanguage={setUserLanguage}
                    />
                </FormStep>
                {displayInfoPage &&
                    <FormStep>
                        <CenterInformation
                            centerPageContent={isEnglish ? programData.EnglishPageContent.InformationalPage : programData.SpanishPageContent.InformationalPage}
                        />
                    </FormStep>
                }
                {isDonor &&
                    <FormStep
                        stepName={stepNames.get(SelfReferralFormSteps.RecipientInfo)}
                        onSubmit={() => console.log("step 1.5")}
                        validationSchema={RecipientInformationValidation(isEnglish)}
                        centerPageContent={isEnglish ? programData.EnglishPageContent.RecipientInformation : programData.SpanishPageContent.RecipientInformation}
                    >
                        <RecipientInformation
                            isEnglish={isEnglish}
                        />
                    </FormStep>
                }
                <FormStep
                    stepName={stepNames.get(SelfReferralFormSteps.BasicInfo)}
                    onSubmit={() => console.log("step 3")}
                    validationSchema={BasicInformationValidation(isEnglish)}
                    centerPageContent={isEnglish ? programData.EnglishPageContent.BasicInformation : programData.SpanishPageContent.BasicInformation}
                >
                    <BasicInformation
                        isEnglish={isEnglish}
                    />
                </FormStep>
                <FormStep
                    stepName={stepNames.get(SelfReferralFormSteps.ContactInfo)}
                    onSubmit={() => console.log("step 4")}
                    validationSchema={ContactInformationValidation(isEnglish)}
                    centerPageContent={isEnglish ? programData.EnglishPageContent.ContactInformation : programData.SpanishPageContent.ContactInformation}
                >
                    <ContactInformation
                        isEnglish={isEnglish}
                    />
                </FormStep>
                <FormStep
                    stepName={stepNames.get(SelfReferralFormSteps.HealthInfo)}
                    onSubmit={() => console.log("step 5")}
                    validationSchema={isDonor ? DonorHealthInformationValidation(isEnglish) : HealthInformationValidation(isKidney, isEnglish)}
                    centerPageContent={isEnglish ? programData.EnglishPageContent.HealthInformation : programData.SpanishPageContent.HealthInformation}
                >
                    <HealthInformation
                        isKidney={isKidney}
                        isDonor={isDonor}
                        isEnglish={isEnglish}
                    />
                </FormStep>
                <FormStep
                    //this step name is used to determine when to submit, not actually displayed like the other steps' stepNames
                    stepName={MISC_CONSTANTS.confirmInformation}
                    onSubmit={() => console.log("step 6")}
                    centerPageContent={isEnglish ? programData.EnglishPageContent.ConfirmInformation : programData.SpanishPageContent.ConfirmInformation}
                >
                    <ConfirmInformation
                        isKidney={isKidney}
                        isDonor={isDonor}
                        isEnglish={isEnglish}
                        stepNames={stepNames}
                    />
                </FormStep>
                <FormStep
                    stepName=""
                    onSubmit={() => console.log("step 7")}
                >
                    <SubmissionConfirmation
                        isEnglish={isEnglish}
                        confirmationNumber={confirmationCode}
                        centerName={programData.CenterName}
                        submissionPageContent={isEnglish ? programData.EnglishPageContent.SubmissionConfirmation : programData.SpanishPageContent.SubmissionConfirmation}
                    />
                </FormStep>
            </SelfReferralForm>
        </div>
    );
}

export default App;
