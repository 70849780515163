//Basic Information
export const firstName = "First Name";
export const lastName = "Last Name";
export const dateOfBirth = "Date of Birth";
export const birthSex = "Birth Sex";

//Recipient Information
export const nonDirectedDonor = "I am willing to donate to any recipient";
export const recipientName = "Preferred Recipient Name";

//Contact Information
export const address1 = "Address Line 1";
export const address2 = "Address Line 2";
export const city = "City";
export const state = "State";
export const zip = "Zip Code";
export const email = "Email";
export const homePhone = "Home Phone";
export const cellPhone = "Cell Phone";
export const prefContactMethod = "Preferred Contact Method";

//Health Information
export const heightUnit = "Height Unit";
export const weightUnit = "Weight Unit";
export const heightFeet = "Feet";
export const heightInches = "Inches";
export const heightCentimeters = "Centimeters";
export const weightPounds = "Pounds";
export const weightKilograms = "Kilograms";
export const physicianName = "Physician Name";
export const numPriorTransplants = "# of Prior Transplants";
export const currentDialysis = "Are you currently on Dialysis?";
export const dialysisCenterName = "Dialysis Center Name";
export const dialysisCenterCity = "Dialysis Center City";
export const dialysisCenterState = "Dialysis Center State";