import { useState } from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormikValues, useFormikContext } from 'formik';
import { styled } from '@mui/material/styles';
import * as MISC_CONSTANTS from '../../../staticResources/MiscConstants';
import ReCAPTCHA from "react-google-recaptcha";
import React from 'react';
import { ISelfReferralFormFields } from '../../../interfaces/ISelfReferralFormFields';

interface Props {
    hasPrevious?: boolean;
    onBackClick: (values: FormikValues) => void;
    isLastStep: boolean;
    recipientName: string;
    programId: string;
    stepName: string;
    reCaptchaRef: React.RefObject<ReCAPTCHA>;
    contactContent: string;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const FormNavigation = ({ reCaptchaRef, ...props }: Props) => {
    const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    const { values } = useFormikContext<ISelfReferralFormFields>();

    const isEnglish = values.Language === MISC_CONSTANTS.english;
    const [isContactOpen, setIsContactOpen] = useState(false);


    const handleContactOpen = () => {
        setIsContactOpen(true);
    };

    const handleContactClose = () => {
        setIsContactOpen(false);
    };

    return (
        <>
            <div className="navButtonContainer">
                {props.hasPrevious ?
                    <Button
                        tabIndex={0}
                        variant={MISC_CONSTANTS.outlined}
                        type='button'
                        onClick={props.onBackClick}
                        className={MISC_CONSTANTS.navButton}
                    >
                        {isEnglish ? 'Back' : 'Atrás'}
                    </Button>
                    :
                    <Button
                        tabIndex={0}
                        variant={MISC_CONSTANTS.outlined}
                        sx={{ width: "130px" }}
                        type='button'
                        onClick={handleContactOpen}
                        className={MISC_CONSTANTS.navButton}
                    >
                        {isEnglish ? 'Contact Us' : 'Contacta con nosotros'}
                    </Button>
                }

                {props.stepName === MISC_CONSTANTS.confirmInformation ?
                    <>
                        <Button
                            tabIndex={0}
                            variant='contained'
                            type='submit'
                            color={MISC_CONSTANTS.primary}
                            className={MISC_CONSTANTS.navButton}
                        >
                            {isEnglish ? 'Submit' : 'Entregar'}
                        </Button>
                        <div>
                            <ReCAPTCHA
                                tabIndex={0}
                                hl={isEnglish ? 'en' : 'es'}
                                style={{ display: "inline-block" }}
                                badge='inline'
                                size="invisible"
                                ref={reCaptchaRef}
                                sitekey={siteKey ?? ''}
                            />
                        </div>
                    </>
                    : props.hasPrevious ?
                        <Button
                            tabIndex={0}
                            variant='contained'
                            type='submit'
                            sx={{ whiteSpace: "pre-line" }}
                            color={MISC_CONSTANTS.primary}
                            className={MISC_CONSTANTS.navButton}
                        >
                            {isEnglish ? 'Continue' : 'Continuar'}
                        </Button>
                        :
                        <Button
                            tabIndex={0}
                            variant='contained'
                            type='submit'
                            sx={{ whiteSpace: "pre-line", width: "130px" }}
                            color={MISC_CONSTANTS.primary}
                            className={MISC_CONSTANTS.navButton}
                        >
                            {isEnglish ? `Continue` : '\r\nContinuar\r\n\r\n'}
                        </Button>
                }

                <p className="pageFooter">
                    {MISC_CONSTANTS.careDxBrand}
                    <br />
                    <a tabIndex={0} href={MISC_CONSTANTS.careDxPrivacyLink} target="_blank" rel="noreferrer">{isEnglish ? "Privacy Statement" : "Declaracion de privacidad"}</a>
                </p>
            </div>

            {/* Contact Dialog */}
            <BootstrapDialog
                open={isContactOpen}
                onClose={handleContactClose}
                aria-labelledby="customized-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ marginRight: 5, p: 2 }} id="customized-dialog-title">
                    Contact Us/Contacta Con Nosotros
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleContactClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <DialogContentText style={{ whiteSpace: 'pre-line' }} id="alert-dialog-description">
                        {props.contactContent}
                    </DialogContentText>
                </DialogContent>
            </BootstrapDialog>
        </>
    )
}

export default FormNavigation