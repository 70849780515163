import InputTextField from '../inputs/InputTextField';
import InputSelectField from '../inputs/InputSelectField';
import { Grid } from '@mui/material';
import InputRadioGroup from '../inputs/InputRadioGroup';
import { HeightUnits, HeightUnitsSpanish, States, WeightUnits, WeightUnitsSpanish, YesNoOptions, YesNoOptionsSpanish } from '../../staticResources/InputOptions';
import { ISelfReferralFormFields } from '../../interfaces/ISelfReferralFormFields';
import { useFormikContext } from 'formik';
import { getLabels } from '../../services/LabelService';
import { SelfReferralFormSteps } from '../../staticResources/FormStepsForLabels';
import * as INPUT_LABEL_KEYS from '../../staticResources/InputLabelKeys';

interface Props {
    isKidney: boolean;
    isDonor: boolean;
    isEnglish: boolean;
}

const HealthInformation = ({ isKidney, isDonor, isEnglish }: Props) => {
    //Getting values here to support conditionally showing certain height and weight fields/labels
    const { values } = useFormikContext<ISelfReferralFormFields>();
    const labels = getLabels(isEnglish, SelfReferralFormSteps.HealthInfo);

    let weightLabel = "";
    if (values.WeightUnit) {
        if (values.WeightUnit === 'pounds') {
            weightLabel = labels.get(INPUT_LABEL_KEYS.weightPoundsLabelKey) ?? '';
        } else {
            weightLabel = labels.get(INPUT_LABEL_KEYS.weightKilogramsLabelKey) ?? '';
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item md={4} sm={12} xs={12} lg={4}>
                <InputRadioGroup
                    name="HeightUnit"
                    label={labels.get(INPUT_LABEL_KEYS.heightUnitLabelKey) ?? ''}
                    options={isEnglish ? HeightUnits : HeightUnitsSpanish}
                />
            </Grid>
            {/* The following grid container is a nested container that allows ft/in to be side by side within a 4-sized (on lg) column */}
            <Grid container item md={4} sm={12} xs={12} lg={4}>
                {values.HeightUnit === 'feetInches' && (
                    <>
                        <Grid item md={6} sm={12} xs={12} lg={6} sx={{ paddingTop: "10px" }}>
                            <InputTextField
                                name="HeightFeet"
                                label={labels.get(INPUT_LABEL_KEYS.heightFeetLabelKey) ?? ''}
                            />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12} lg={6} sx={{ paddingTop: "10px" }}>
                            <InputTextField
                                name="HeightInches"
                                label={labels.get(INPUT_LABEL_KEYS.heightInchesLabelKey) ?? ''}
                            />
                        </Grid>
                    </>
                )}
                {values.HeightUnit === 'centimeters' && (
                    <Grid item md={12} sm={12} xs={12} lg={12}>
                        <InputTextField
                            name="HeightCentimeters"
                            label={labels.get(INPUT_LABEL_KEYS.heightCentimetersLabelKey) ?? ''}
                        />
                    </Grid>
                )}
            </Grid>
            {/* These empty 100 width divs allow preceding grid rows that add up to less than 12  */}
            <div className="fillerDiv"></div>
            <Grid item md={4} sm={12} xs={12} lg={4}>
                <InputRadioGroup
                    name="WeightUnit"
                    label={labels.get(INPUT_LABEL_KEYS.weightUnitLabelKey) ?? ''}
                    options={isEnglish ? WeightUnits : WeightUnitsSpanish}
                />
            </Grid>
            {values.WeightUnit && (
                <Grid item md={4} sm={12} xs={12} lg={4}>
                    <InputTextField
                        name="Weight"
                        label={weightLabel}
                    />
                </Grid>
            )}
            <div className="fillerDiv"></div>

            {!isDonor &&
                <>
                    <Grid item md={4} sm={12} xs={12} lg={4}>
                        <InputTextField
                            name="PhysicianName"
                            label={labels.get(INPUT_LABEL_KEYS.physicianNameLabelKey) ?? ''}
                        />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} lg={4}>
                        <InputTextField
                            name="NumPriorTransplants"
                            label={labels.get(INPUT_LABEL_KEYS.numPriorTransplantsLabelKey) ?? ''}
                        />
                    </Grid>
                    {isKidney && (
                        <>
                            <Grid item md={4} sm={12} xs={12} lg={4}>
                                <InputRadioGroup
                                    name="CurrentDialysis"
                                    label={labels.get(INPUT_LABEL_KEYS.currentDialysisLabelKey) ?? ''}
                                    options={isEnglish ? YesNoOptions : YesNoOptionsSpanish}
                                />
                            </Grid>
                            <div style={{ width: '100%' }}></div>
                            {values.CurrentDialysis === 'yes' && (
                                <>
                                    <Grid item md={4} sm={12} xs={12} lg={4}>
                                        <InputTextField
                                            name="DialysisCenterName"
                                            label={labels.get(INPUT_LABEL_KEYS.dialysisCenterNameLabelKey) ?? ''}
                                        />
                                    </Grid>
                                    <Grid item md={4} sm={12} xs={12} lg={4}>
                                        <InputTextField
                                            name="DialysisCenterCity"
                                            label={labels.get(INPUT_LABEL_KEYS.dialysisCenterCityLabelKey) ?? ''}
                                        />
                                    </Grid>
                                    <Grid item md={4} sm={12} xs={12} lg={4}>
                                        <InputSelectField
                                            name="DialysisCenterState"
                                            label={labels.get(INPUT_LABEL_KEYS.dialysisCenterStateLabelKey) ?? ''}
                                            options={States}
                                        />
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                </>
            }
        </Grid>
    );
}

export default HealthInformation
